import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Link,
} from '@mui/material';
import React from 'react';

import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { theme } from '@app/theme';
import {
  CategoryType,
  ProductVariant,
  ProductVariantFloorPlanCategory,
} from '@app/types/catalog';
import { formatPrice } from '@app/utils/format';

interface PropertyFloorPlanProps {
  organizationId: string;
  parentOrganizationId?: string;
  productId?: string;
  variants: ProductVariant[];
}

export function PropertyFloorPlan({
  productId,
  variants,
  parentOrganizationId,
}: PropertyFloorPlanProps) {
  const { isMobile } = useDeviceType();
  return (
    <Box sx={{ mb: isMobile ? 3 : 10, mt: 3 }}>
      <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
        間取り・プラン
      </Typography>
      <Typography variant="body2" fontWeight={700} sx={{ mb: 2 }}>
        公開中: {variants.length}件
      </Typography>
      <Grid container spacing={2}>
        {variants.length > 0 && (
          <>
            {variants.map((variant) => (
              <Grid item xs={isMobile ? 12 : 6} key={variant.id}>
                <Card
                  sx={{
                    border: `1px solid ${theme.palette.neutral.silver}`,
                    height: '100%',
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ mb: 2 }}
                    >
                      {
                        Object.values(ProductVariantFloorPlanCategory).find(
                          (category) =>
                            category.key === variant.customFields?.category
                        )?.label
                      }
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Typography variant="body2">間取り</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2">
                          {variant.description || '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">土地面積</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2">
                          {(Number(variant.customFields?.landArea) > 0 &&
                            variant.customFields?.landArea) ||
                            '-'}{' '}
                          ㎡
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">建物面積</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2">
                          {(Number(variant.customFields?.buildingArea) > 0 &&
                            variant.customFields?.buildingArea) ||
                            '-'}{' '}
                          ㎡
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">販売価格</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2">
                          {variant.customFields?.price &&
                          Number(variant.customFields.price) > 0 &&
                          variant.customFields?.price.trim() !== '' ? (
                            <>
                              {formatPrice(Number(variant.customFields.price))}{' '}
                              万円
                            </>
                          ) : (
                            <>
                              <Link
                                href={`/companies/${parentOrganizationId}/inquiry/create?category=${CategoryType.PROPERTY}&productId=${productId}`}
                                color="primary"
                              >
                                価格をお問い合わせ（無料）
                              </Link>
                            </>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* NOTE: v1.2.0で非表示化
                    <Typography variant="body2" sx={{ my: 2 }}>
                      コメント: {variant.customFields?.comment || '-'}
                    </Typography> */}
                  </CardContent>
                  {variant.images?.[0]?.url && (
                    <CardMedia
                      component="img"
                      sx={{
                        height: '200px',
                        mb: 3,
                        objectFit: 'contain',
                        width: '100%',
                      }}
                      image={variant.images[0].url}
                      alt={
                        Object.values(ProductVariantFloorPlanCategory).find(
                          (category) =>
                            category.key === variant.customFields?.category
                        )?.label
                      }
                    />
                  )}
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
}
